<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link to="/asb_kewajaran_belanja/list_dinas">Daftar Dinas</router-link>
                                </li>
                                <li class="breadcrumb-item">
                                    <router-link to="/asb_kewajaran_belanja/detail_dinas/sub_kegiatan">Dinas Pendidikan Dan Kebudayaan
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active">Detail Sub Kegiatan </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none">
                <router-link to="/asb_kewajaran_belanja/detail_dinas/sub_kegiatan">
                    <button type="button" class="btn btn-primary btn-block waves-effect waves-float waves-light"><i
                            class="fa fa-backward mr-1"></i>Kembali</button>
                </router-link>
            </div>


        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card ">
                    <div class="card-body">
                        <h4 class="card-title">Detail Sub Kegiatan</h4>
                        <div class="">
                            <div class="row text-center">
                                <div class="col-lg-12"><b>X</b></div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 text-right"><b>X.XX. </b></div>
                                <div class="col-lg-8"><b> </b></div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 text-right">01</div>
                                <div class="col-lg-8">Program Penunjang Urusan Pemerintahan Daerah</div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 text-right">01.2,03</div>
                                <div class="col-lg-8">Administrasi Umum</div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 text-right">01.2,03.16</div>
                                <div class="col-lg-8">Penyediaan Bahan Logistik Kantor</div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 h5 text-center"><br>Kategori Formula ASB 028 - Penyediaan
                                    Barang/Jasa (belanja rutin)</div>
                            </div> 
                        </div> 
                        <div class="row">
                            <div class="col-md-12 col-xl-12">
                                <div class="card bg-secondary  text-white">
                                    <div class="card-body text-center">
                                        <h5 class="text-white">Total Belanja Administrasi Proyek Kegiatan = 5.0 % X
                                            belanja modal peralatan yang dianggarkan</h5>
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group text-center">
                                    <h6 class="text-center">Batas Atas Belanja Sub kegiatan Menurut ASB </h6>
                                    <h5 id="rupiah"><b>Rp. 48.000</b></h5>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group text-center">
                                    <h6 class="text-center">Total Belanja RKA </h6>
                                    <h5 id="rupiah"><b>Rp. 40.000</b></h5>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 col-xl-12">
                                <div class="card bg-info  text-white">
                                    <div class="card-body text-center">


                                        <h2 class="text-white">
                                            <feather-icon icon="CheckCircleIcon" size="25" /> Kategori Wajar</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <b-form-group label="Non Fisik" label-for="Sub OPD">
                            <b-form-select v-model="selected" :options="options"  />
                        </b-form-group> -->

                        <TabelBelanja></TabelBelanja>
                    </div>
                </div>
            </div>
        </div>




    </div>

</template>

<script>
    import TabelBelanja from './TabelBelanja.vue';
    import vSelect from 'vue-select';
    import {
        BFormSelect,
        
        BFormInput,
        BFormGroup,
        BRow,
        BCol,
    } from 'bootstrap-vue'

    export default {
        components: {
            
            BFormSelect,
            BFormInput,
            BFormGroup,
            BRow,
            BCol,
            vSelect,
            TabelBelanja
        },
        data() {
            return {
                currentPage: 1,
                perPage: 1,
                rows: 100,
                selected: {
                    title: 'Square'
                },
                selected1: {
                    title: 'Aperture',
                    icon: 'ApertureIcon',
                },
                option: [{
                    title: 'Square'
                }, {
                    title: 'Rectangle'
                }, {
                    title: 'Rombo'
                }, {
                    title: 'Romboid'
                }],
                books: [{
                        title: 'Aperture',
                        icon: 'ApertureIcon',
                    },
                    {
                        title: 'Codepen',
                        icon: 'CodepenIcon',
                    },
                    {
                        title: 'Globe ',
                        icon: 'GlobeIcon',
                    },
                    {
                        title: 'Instagram ',
                        icon: 'InstagramIcon',
                    },
                ],
            }
        }
    }
</script>

<style>

</style>