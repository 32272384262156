<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-2">
                <h5>Tabel Belanja Menurut Standar Satuan Harga Tahun 2023</h5>
            </div>
            <div class="content-header-right text-md-right col-md-3 col-12 d-md-block d-none"> 
            </div>
 
        </div>
        <div class="row">
            <div class="col-12">
   <div class="table-responsive">
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Kode</th>
                                <th>Nama barang</th>
                                <th>Harga / Satuan</th>
                                <th>Volume</th>
                                <th>Harga</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>1</td>
                                <td>5-2-1-1</td>
                                <td>Baygon Spray (isi 275 gram)</td>
                                <td>Rp. 20.000,- / Kaleng</td>
                                <td>2 Kaleng</td>
                                <td>Rp. 40.000,-</td>
                            </tr>
                            <tr>

                                <td>1</td>
                                <td>5-2-1-1</td>
                                <td>Baygon Spray (isi 275 gram)</td>
                                <td>Rp. 20.000,- / Kaleng</td>
                                <td>2 Kaleng</td>
                                <td>Rp. 40.000,-</td>
                            </tr>
                            <tr>

                                <td>1</td>
                                <td>5-2-1-1</td>
                                <td>Baygon Spray (isi 275 gram)</td>
                                <td>Rp. 20.000,- / Kaleng</td>
                                <td>2 Kaleng</td>
                                <td>Rp. 40.000,-</td>
                            </tr>

                            <tr>

                                <td colspan="5"><b style="float: right;">Total : </b></td>

                                <td><b>Rp. 40.000,-</b></td>


                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
     
    import {
        BButton,
        BModal,
        VBModal
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BButton,
            BModal, 
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
    }
</script>

<style>

</style>